.background-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust height as needed */
  background-image: url('/src//components//image/B2B\ image.jpg'); /* Use the correct path to your image */
  background-size: cover;
  background-position: 100% ;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin: 20px auto; */
}
@media (max-width: 768px) {
  .background-container {
    height: 70vh; /* Reduce height on smaller screens */
    background-position: 16%; /* Adjust the position if needed */
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .background-container {
    height: 60vh; /* Further reduce height on very small screens */
    background-position: 16%; 
    padding: 10px;
  }
}
@media (max-width: 1366px){
  .background-container{
    /* height: 100vh; */
    background-position: 16%;
  }
}
.overlay-text {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  z-index: 10;
  white-space: nowrap;
}

.get-started-button {
  position: absolute;
  bottom: 10%;
  right: 5%;
  background-color: #cde052;
  color: rgb(16, 14, 14);
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  opacity: 1; /* Start with the button invisible */
  transform: scale(1); /* Start with a smaller size */
  animation: popUp 2s ease-in-out forwards; /* Apply the pop-up animation */
}

.get-started-button:hover {
  background-color: #0056b3;
}
/* services-container */

.services-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  background-color: #162329;
  /* border-radius: 10px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 30px;
  color: #ede9e9;
  font-weight: bold;
}

.service {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #0e0d0c;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(176, 188, 216, 0.2);
}

.service h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #007bff;
}

.service p {
  font-size: 1.1rem;
  color: #ebe6e6;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .services-title {
    font-size: 2rem;
  }

  .service h3 {
    font-size: 1.5rem;
  }

  .service p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .services-title {
    font-size: 1.8rem;
  }

  .service h3 {
    font-size: 1.3rem;
  }

  .service p {
    font-size: 0.9rem;
  }
}


@media (max-width: 768px) {
  .overlay-text {
    font-size: 1.5rem;
  }

  .get-started-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .overlay-text {
    font-size: 1.2rem;
  }

  .get-started-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
/* Define the pop-up animation */
@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Hover effect for the button */
.get-started-button:hover {
  background-color: #9aaba1;
}
/* WhyChooseUs.css */

body {
  background-color: #162329;
}

.why-choose-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #fff;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  line-height: 60px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 32px;
  padding: 30px;
}

.card {
  border: 0;
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  background-clip: padding-box;
  border-radius: 45px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.card h3 {
  font-weight: 600;
  color: blue;
  font-size: 28px;
  margin-bottom: 8px;
}

.card p {
  font-weight: 400;
  color: hsla(0, 0%, 100%, 0.8);
  font-size: 16px;
  line-height: 1.6;
}

.bg-green-box::after {
  background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
}

.bg-white-box::after {
  background: linear-gradient(71deg, #121013, #b0afb0, #121013);
}

.bg-yellow-box::after {
  background: linear-gradient(71deg, #110e0e, #afa220, #110e0e);
}

.bg-blue-box::after {
  background: linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e);
}

/* Responsive adjustments */
@media (max-width: 991px) {
  .cards-container {
    grid-template-columns: 1fr;
  }
}
/* IndustriesWeServe.css */


.industries-serve-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #0a1319;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-left {
  flex: 1;
  text-align: center;
  padding: 20px;

}

.text-left h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: white;
}

.text-left p {
  font-size: 2em;
  color: #ebe4de;
}

.image-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.industry-image {
  width: 100%;
  height: auto;
  max-width: 500px; /* Set a maximum width for the image */
  border-radius: 10px;
}

@media (max-width: 768px) {
  .industries-serve-container {
      flex-direction: column;
  }

  .text-left {
      margin-bottom: 20px;
  }

  .industry-image {
      max-width: 90%; /* Adjust max-width for smaller screens */
  }
}



.import-confidence-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333f3e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
  color: wheat;
}

.text-left, .text-right {
  width: 48%; /* Adjust width to leave some space between the two sides */
}

.text-left {
  animation: slideInLeft 1.5s ease-in-out;
}

.text-right {
  animation: slideInRight 1.5s ease-in-out;
}

.animated-text {
  font-size: 2em;
  margin-bottom: 15px;
  color: rgb(239, 234, 224);
  font-weight: bold;
}

.text-right h3 {
  font-size: 1.3em;
  color: wheat;
}

@media (max-width: 768px) {
  .import-confidence-container {
      flex-direction: column;
      text-align: center;
  }

  .text-left, .text-right {
      width: 100%;
  }

  .text-right h3 {
      font-size: 1.1em;
      margin-top: 20px;
  }
}

/* Keyframe animations */
@keyframes slideInLeft {
  from {
      transform: translateX(-100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideInRight {
  from {
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}


.contact-us-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.contact-us-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .start-importing-title {
    font-size: 2rem;
  }

  .start-importing-description {
    font-size: 1rem;
  }

  .contact-us-button {
    font-size: 1rem;
    padding: 12px 24px;
  }
}

@media (max-width: 480px) {
  .start-importing-title {
    font-size: 1.6rem;
  }

  .start-importing-description {
    font-size: 0.9rem;
  }

  .contact-us-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}
