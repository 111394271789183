/* Segment.css */
.segment-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding-top: 20px;
  margin-top: 56px;
  background-color: black;
}
.segment-containe {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding-top: 20px;
  /* margin-top: 56px; */
  background-color: black;
}

.segment {
    margin-top: 50px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 300px;
  flex: 1 1 calc(33.333% - 40px);
  margin: 20px;
  transition: transform 0.2s;
  text-align: center;
}

.segment:hover {
  transform: translateY(-5px);
}

.segment-image {
  width: 100%;
  height: auto;
}

.segment-content {
  padding: 15px;
}

.segment-title {
  font-size: 1.5em;
  margin: 10px 0;
  color: white;
}

.segment-description {
  font-size: 1em;
  color: #666;
  margin: 10px 0;
  color: rgb(217, 211, 234);
}

.segment-button {
  background-color: #0099ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
}

.segment-button:hover {
  background-color: #007acc;
}
.trusted{
  color: white;
  font-family: 'Times New Roman', Times, serif;
  text-decoration: solid;
  font-size: 4rem;
  box-sizing: content-box;
  background: rgb(15, 16, 16);
  margin: 20px;
}
.trusted-description{
  margin: 20px;

}
/* Responsive Design */
@media (max-width: 1200px) {
  .segment {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .segment {
    flex: 1 1 calc(100% - 40px);
  }
}
