.d2c-container{
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
.responsive-image-container {
    width: 100%;
    max-width: 1200px; /* Adjust the max-width as needed */
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  
  .responsive-image {
    margin-top: 65px;
    width: 100%;
    height: 100%;
    display: block;
    background-position: center;
    background-size: cover;
    object-fit: cover; /* Ensures the image covers the container while maintaining its aspect ratio */
  }
  
  /* Overlay Text Styling */
.overlay-text {
    background: rgb(89,38,81);
    background: linear-gradient(90deg, rgba(89,38,81,0.6056547619047619) 1%, rgba(147,27,84,0.4095763305322129) 44%, rgba(0,36,29,0.6056547619047619) 93%);
    border-radius: 10px;
    text-decoration: transparent;
    position: absolute;
    top: 77px; /* Adjust the top spacing from the edge of the image */
    left: 50%;
    transform: translateX(-50%); /* Center the text horizontally */
    color: white; /* Adjust text color for visibility */
    text-align: center;
    width: calc(70% - 40px); /* To ensure text is within padding of container */
    box-sizing: border-box;
    /* padding: 20px; */
  }
  
  .export-logistics-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    background-color: #162329;
    color: #eee4e4;
}

.export-logistics-content {
    max-width: 800px;
    text-align: center;
    background-color: #050505;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.export-logistics-content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #004080;
}

.export-logistics-content p {
    font-size: 1.125rem;
    line-height: 1.6;
    margin-bottom: 15px;
    color: white;
}

@media screen and (max-width: 768px) {
    .export-logistics-content h2 {
        font-size: 1.5rem;
    }

    .export-logistics-content p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .export-logistics-content {
        padding: 20px;
    }

    .export-logistics-content h2 {
        font-size: 1.25rem;
    }

    .export-logistics-content p {
        font-size: 0.875rem;
    }
}

  
  .overlay-text h1 {
    font-size: 1em;
    margin: 0;
  }
  
  .overlay-text h2 {
    font-size: 1em;
    margin: 10px 0 0;
  }

 
  /* Bottom Right Button Styling */
.bottom-right-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #592651; /* Matching the gradient colors */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .bottom-right-button:hover {
    background-color: #931b54; /* Lighter shade on hover */
  }
  
 .export-services-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.image-section {
    
  flex: 1;
  padding: 20px;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.image-section.fade-in {
  opacity: 1;
  transform: translateX(0);
}

.image-section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.text-section {
  flex: 1;
  padding: 20px;
}
.text-section h2 {
  color: floralwhite;
}
.text-section h3 {
  color: #1818c7;
}
.text-block {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1s ease-out, transform 1s ease-out;
  margin-bottom: 20px;
}

.text-block.fade-in {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 768px) {
  .export-services-container {
    flex-direction: column-reverse;
  }

  .text-block {
    transform: translateY(50px);
  }

  .text-block.fade-in {
    transform: translateY(0);
  }

  .image-section {
   
    transform: translateY(-50px);
  }

  .image-section.fade-in {
    transform: translateY(0);
  }

  .image-section {
    order: 2;
    margin-bottom: 20px;
  }
}




/* Responsive Design */
@media (max-width: 768px) {
    .export-logistics-container {
        flex-direction: column;
        align-items: center;
    }   
}


/* Responsive Design */


  /* Responsive Design */
  @media (max-width: 768px) {
    .overlay-text {
      top: 70px; /* Adjust the top spacing for smaller screens */
      left: 50%;
      transform: translateX(-50%); /* Center the text horizontally */
      width: calc(100% - 40px); /* Adjust width for smaller screens */
    }
  
    .overlay-text h1 {
      font-size: 1.5em;
    }
  
    .overlay-text h2 {
      font-size: 1.2em;
    }
    
    .bottom-right-button {
        bottom: 10px;
        right: 10px;
        padding: 8px 16px;
        font-size: 0.9em;
      }
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .responsive-image-container {
      padding: 10px;
    }
  }
  