/* Footer.css */
.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .footer_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .footer_section{
    margin-left: 52px;
  }
  .footer_section {
    flex: 1;
    min-width: 200px;
    margin-bottom: 20px;
  }
  
  .footer_title {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .footer_text {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .footer_links {
    list-style: none;
    padding: 0;
  }
  
  .footer_links li {
    margin-bottom: 10px;
  }
  
  .footer_links a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer_links a:hover {
    text-decoration: underline;
  }
  
  .footer_bottom {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid #444;
  }
  
  .footer_bottom_text {
    font-size: 14px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .footer_content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer_section {
      margin-bottom: 30px;
      text-align: center;
    }
  }
  