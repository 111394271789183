.promo-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: #000; /* Black background */
    color: #fff; /* White text color */
  }
  
  .text-container {
    max-width: 50%;
  }
  
  .text-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .text-container p {
    font-size: 1.25rem;
    margin-bottom: 30px;
  }
  
  .btn-get-started {
    display: inline-block;
    padding: 15px 30px;
    background-color: #4a64e2; /* Button background color */
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .image-container {
    max-width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  .fulfillment-section {
    padding: 50px;
    background-color: #000; /* Black background */
    color: #fff; /* White text color */
    text-align: center;
  }
  
  .fulfillment-section h1 {
    font-size: 3rem;
    margin-bottom: 40px;
    text-align: left;
  }
  
  .cards-container {
    display: flex;
    justify-content: center;

    gap: 20px;
    flex-wrap: wrap;
    /* height: max-content; */
    
  }
  
  .card {
    background-color: #1a1a1a; /* Dark card background */
    border-radius: 8px;
    padding: 30px;
    width: 90vh;
    height: 45vh;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .card h2 {
    color: #1dbfc8; /* Green color for the title */
    margin-bottom: 20px;
  }
  
  .card ul {
    list-style-type: none;
    padding: 0;
    color: white;
  }
  
  .card li {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .cards-container {
      flex-direction: column;
      align-items: center;
    }
  
    .card {
      width: 100%;
      max-width: 500px;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .promo-section {
      flex-direction: column;
      text-align: center;
    }
  
    .text-container, .image-container {
      max-width: 100%;
    }
  
    .image-container {
      margin-top: 20px;
    }
  }
  