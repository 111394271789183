.career-container {
    margin-top: 50px;
    padding: 40px 20px;
    background-color: #716868;
  }
  
  .career-heading {
    text-align: center;
    font-size: 32px;
    color: whitesmoke;
    margin-bottom: 30px;
  }
  
  .career-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .career-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 300px;
    text-align: center;
  }
  
  .job-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .job-location {
    font-size: 16px;
    color: #777;
    margin-bottom: 15px;
  }
  
  .job-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .apply-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .apply-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .career-card {
      width: 100%;
    }
  
    .career-heading {
      font-size: 28px;
    }
  }
  
  @media (max-width: 480px) {
    .career-heading {
      font-size: 24px;
    }
  
    .apply-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  