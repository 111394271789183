.testimonial-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .testimonial-container {
    margin-top: 10vh;
  }
  .testimonial-containe {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .testimonial-containe {
    margin-top: 10vh;
  }
  .testimonial-top{
    height: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1000px;
    background-color: #50807b;
    padding: 20px;
    border-radius: 8px;
    margin: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .testimonial {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1000px;
    background-color: #50807b;
    padding: 20px;
    border-radius: 8px;
    margin: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-image {
    flex: 1;
    max-width: 150px;
    margin-right: 20px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .testimonial-content {
    flex: 3;
    text-align: left;
  }
  
  .testimonial-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .testimonial-rating {
    color: #f39c12;
    margin-bottom: 10px;
  }
  
  .testimonial-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .testimonial-author {
    font-weight: 600;
    margin-top: 10px;
  }
  
  .testimonial-role {
    font-weight: 400;
    font-style: italic;
  }
  
  .testimonial-company {
    font-weight: 600;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .testimonial {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .testimonial-image {
      max-width: 100px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .testimonial-content {
      text-align: left;
    }
  }
  