.industries-container {
  background-color: #062544;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .text-section {
    flex: 1;
    padding: 20px;
  }
  
  .header {
    font-size: 2.5em;
    margin-bottom: 20px;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .header.fade-in {
    opacity: 1;
    transform: translateX(0);
  }
  
  .text-content {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 1s ease-out, transform 1s ease-out;
    margin-bottom: 20px;
  }
  
  .text-content.fade-in {
    opacity: 1;
    transform: translateX(0);
  }
  
  .image-section {
    flex: 1;
    padding: 20px;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .image-section.fade-in {
    opacity: 1;
    transform: translateX(0);
  }
  
  .image-section img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .industries-container {
      flex-direction: column;
    }
  
    .text-section {
      order: 1;
    }
  
    .image-section {
      order: 2;
      margin-bottom: 20px;
    }
  
    .header, .text-content {
      transform: translateY(50px);
    }
  
    .header.fade-in, .text-content.fade-in {
      transform: translateY(0);
    }
  
    .image-section {
      transform: translateY(-50px);
    }
  
    .image-section.fade-in {
      transform: translateY(0);
    }
  }
  