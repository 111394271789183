.contact-form-containe {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
 
}
.contact-form-container {
background: black;
color: white;
margin-bottom: 0;
height: 280px;
}

.contact-form-containe h1 {
  font-size: 2.5em;
  margin-bottom: 0%;
  text-align: center;
  /* margin-left: 20px; */
}
.contact-form-containe h1 {
  margin-left: 20px;
}
.contact-form-containe p {
  font-size: 1.2em;
  margin-bottom: 30px;
  text-align: center;
}
.contact-form-container p {
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 30px;
}

.contact-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 40px;
}

.form-group {
  flex: 1 1 45%;
  display: flex;
  align-items: center;
  position: relative;
}

.form-group label {
  width: 100%;
}

.icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #00ff00;
}

input {
  width: 100%;
  padding: 10px 10px 10px 35px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  background-color: #333;
  color: #fff;
}
.submit-button {
  flex: 1 1 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  /* background-color: #00ff00; */
  color: #000;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #00cc00;
}
