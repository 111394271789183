.bg-body-tertiary {
  background-color: black !important; /* Override the existing style */
}
.container-fluid {
    background-color: black;
    height: auto;
    z-index: 1000;

}
.navbar-brand{
  font-size: 2rem;
}
.navbar-logo {
  width: 80px; /* Adjust the width as needed */
  height: 45px;
}
.navbar{
  top:0px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  /* background-color: black; */
}
.navbar .navbar-brand,
.navbar .nav-link,
.navbar .navbar-toggler {
  color: white; /* Change text color to white */
}

.navbar .nav-link.active {
  color: white; /* Ensure active link text color is white */
}
.nav-item.dropdown:hover .dropdown-menu {
    display: block;
  }
.navbar-toggler-icon{
    background-color: transparent;
}
  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
    margin-top: 0;
  }
  .dropdown-menu .dropdown-item {
    font-size: 0.675rem; /* Adjust the font size for individual items */
  }