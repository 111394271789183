.our-services-container {
    background-color: rgb(12, 14, 15);
    color: rgb(228, 228, 228);
    padding: 20px;
  }
  
  .our-services-container h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
  }
  
  .service-item {
    margin-bottom: 20px;
    padding: 20px;
    background-color: rgb(62, 61, 61);
    border-radius: 8px;
  }
  
  .service-item h2 {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }
  
  .service-item p {
    font-size: 1.125rem;
  }
  
  @media (max-width: 768px) {
    .our-services-container h1 {
      font-size: 2rem;
    }
  
    .service-item h2 {
      font-size: 1.5rem;
    }
  
    .service-item p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .our-services-container h1 {
      font-size: 1.75rem;
    }
  
    .service-item h2 {
      font-size: 1.25rem;
    }
  
    .service-item p {
      font-size: 0.875rem;
    }
  }
  