.brands-section {
  text-align: center;
  padding: 2rem 1rem;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background: black;
  color: white;
}

.brands-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.brands-logos {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.scrolling-logos {
  display: flex;
  width: 200%; /* Make it wide enough to scroll seamlessly */
}

.scroll-left {
  animation: scrollLeft 20s linear infinite;
}

.scroll-right {
  animation: scrollRight 20s linear infinite;
}

.scrolling-logos img {
  margin: 0 2vw;
  height: auto;
  max-width: 100px;
  object-fit: contain;
  transition: transform 0.2s;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Moves the content left by 50% */
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50%); /* Moves the content right by 50% */
  }
}

/* Responsive adjustments for different screen sizes */
@media (max-width: 768px) {
  .scrolling-logos img {
    max-width: 80px;
    margin: 0 1.5vw;
  }
}

@media (max-width: 480px) {
  .scrolling-logos img {
    max-width: 60px;
    margin: 0 1vw;
  }
}
