.about-us-container {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #100f0f;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-content {
    flex: 1;
    margin: 20px;
    max-width: 600px;
  }
  
  .about-us-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #19c13b;
  }
  
  .about-us-description, .about-us-mission, .about-us-vision {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #d9d2d2;
    line-height: 1.6;
  }
  
  .about-us-image {
    flex: 1;
    margin: 20px;
    display: flex;
    justify-content: center;
  }
  
  .about-us-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .about-us-container {
      flex-direction: column;
      text-align: center;
    }
  
    .about-us-content, .about-us-image {
      margin: 0 0 20px 0;
    }
  
    .about-us-title {
      font-size: 2em;
    }
  
    .about-us-description, .about-us-mission, .about-us-vision {
      font-size: 1.1em;
    }
  
    .about-us-image img {
      max-width: 100%;
    }
  }
  