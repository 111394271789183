.truck-container {
  
  position: relative;
  width: 100%;
  height: 70%;
}

.img-responsive {
  margin-top: 73px;
  width: 100%;
  height: auto;
  /* display: block;
  object-fit: cover; */
  max-width: 100%;
}

.search-bar-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Increased width of the container */
  max-width: 1200px; 
}

.search-bar {
  font-family:Georgia, serif;
  background: transparent;
  width: 100%;
  padding: 20px;
  font-size: 3.5rem;
  border: 1px  solid #333;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(234, 229, 229, 0.1);
  text-shadow: 3px 2px 7px rgba(0, 0, 0, 0.5); /* Adds shadow to the text */
}

@media (max-width: 768px) {
  .search-bar {
    font-size: 16px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .search-bar {
    font-size: 14px;
    padding: 6px;
  }
}
